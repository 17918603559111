import React from 'react'
import { Typography, createStyles, withStyles } from '@material-ui/core'

import Head from 'react-helmet'

import Layout from '../../../components/Layout'

import Location from '../../../Location/Location.js'

const styles = (theme) => {
  return createStyles({
    title: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '36px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '34px',
      },
      fontSize: '24px',
    },
    howToApply: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        marginTop: '120px',
        fontSize: '36px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '120px',
        fontSize: '34px',
      },
      marginTop: '120px',
      fontSize: '24px',
    },
    list: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
        margin: '0 0 5px 0',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
        margin: '0 0 5px 0',
      },
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '1.4',
      margin: '0 0 5px 0',
    },
    text: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '1.4',
      },
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '1.4',
    },
    header: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        fontWeight: '800',
        margin: '35px 0',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: '800',
        margin: '35px 0',
      },
      fontSize: '12px',
      fontWeight: '800',
      margin: '20px 0',
    },
    description: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        textTransform: 'uppercase',
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: '800',
      },
      [theme.breakpoints.up('md')]: {
        textTransform: 'uppercase',
        textDecoration: 'underline',
        fontSize: '14px',
        fontWeight: '800',
      },
      textTransform: 'uppercase',
      textDecoration: 'underline',
      fontSize: '10px',
      fontWeight: '800',
    },
    section: {
      color: '#F0EDEF',
      [theme.breakpoints.up('lg')]: {
        fontFamily: 'Montserrat',
        maxWidth: '67.75rem',
        margin: '0 auto',
        marginTop: '5.8125rem',
        marginBottom: '7.1875rem',
      },
      [theme.breakpoints.up('md')]: {
        fontFamily: 'Montserrat',
        maxWidth: '67.75rem',
        margin: '0 auto',
        marginTop: '5.8125rem',
        marginBottom: '7.1875rem',
      },
      fontFamily: 'Montserrat',
      maxWidth: '90%',
      margin: '0 auto',
      marginTop: '5.8125rem',
      marginBottom: '7.1875rem',
    },
  })
}

const SeniorSoftwareEngineer = ({ classes }) => {
  return (
    <Layout>
      <Head>
        <title>Senior Back-End Engineer</title>
      </Head>
      <section className={classes.section}>
        <Typography variant="h1" className={classes.title}>
          Senior Back-End Engineer
        </Typography>
        <Location location='Los Angeles' />
        <p className={classes.description}>Description</p>
        <h3 className={classes.header}>About Us:</h3>
        <p className={classes.text}>
          At Avocado Finance, we are a little bit peculiar. We are passionate about creating a personal finance experience that customers love and find valuable. If you are customer-obsessed, like interacting with people, and like learning new things, this is the place for you.
        </p>
        <h3 className={classes.header}>About You:</h3>
        <p className={classes.text}>
          As a Senior Back-End Engineer, you will play an instrumental role in building products that help millions of people improve their relationship with money. We're looking for passionate software engineers interested in full stack development. If you're excited to join a tight-knit collaborative team with a mission of helping others, we'd love to hear from you.
        </p>
        <h3 className={classes.header}>What You Do:</h3>
        <div>
          <p className={classes.list}>-  We’re looking for talented Software Engineers to play an instrumental role in building products that help millions of people
            questions</p>
          <p className={classes.list}>-  Design, develop and optimize scalable backend infrastructure to support the core product
          </p>
          <p className={classes.list}>-  Create APIs to integrate with front end and 3rd party systems from highly distributed and independent microservices</p>
          <p className={classes.list}>-  Serve as a key member of the backend design team through strong collaboration</p>
          <p className={classes.list}>-  Contribute to the development of the team, our standards, and best practices</p>
          <p className={classes.list}>-  Identify bottlenecks in our efficiency as a team (“tech debt”), propose and implement solutions</p>

          <p className={classes.text}>Candidates must be at least 18 years of age.</p>
          <p className={classes.text}>Avocado Finance does not sponsor for immigration, including for H-1B, TN, and other non-immigrant visas, for this role.</p>
        </div>
        <br />

        <h3 className={classes.description}>BASIC QUALIFICATIONS</h3>
        <div>
          <p className={classes.list}>-  5+ years of professional software development</p>
          <p className={classes.list}>-  Strong CS fundamentals and problem-solving skills</p>
          <p className={classes.list}>-  Extensive experience with Python (or other OO languages, such as Ruby or Java)</p>
          <p className={classes.list}>-  Strong software architecture and design skills, solid CS fundamentals, pragmatic software design and architecture patterns, and their best usage</p>
          <p className={classes.list}>-  Excellent data modeling skills and strong experience with query optimization</p>
          <p className={classes.list}>-  Ability to clearly and concisely communicate complex technical and architectural concepts and ideas with a wide variety of stakeholders</p>
        </div>
        <br />

        <h3 className={classes.description}>PREFERRED QUALIFICATIONS</h3>
        <div>
          <p className={classes.list}>-  Comfortable working in a dynamic environment that requires adaptability</p>
          <p className={classes.list}>-  Demonstrated positive work attitude</p>
          <p className={classes.list}>-  Strong organizational & leadership skills</p>
          <p className={classes.list}>-  Prior experience at a Fintech startup</p>
        </div>
        <h3 className={classes.howToApply}>How to Apply</h3>
        <div>
          <p className={classes.text}>Send an email with your resume to <a href="mailto: support@avocadofinance.io" style={{ color: '#FF0080', fontWeight: '800', textDecoration: 'none' }}>support@avocadofinance.io</a>.</p>
        </div>
      </section>
    </Layout>
  )
}

export default withStyles(styles)(SeniorSoftwareEngineer)